export default {
    //When the bound element is inserted into the DOM...
    bind: function (el, binding, vnode) {
            el.classList.add('alpha-feature');
        if (!vnode.context.$userData || !vnode.context.$userData.rights.accessAlphaFeatures) {
            el.classList.add('hidden');
            el.style.display = 'none';
            el.style.pointerEvents = 'none';
            el.disabled = true;
        }
    }
}