import {defineStore} from 'pinia'

export const useIrisMessengerStore = defineStore('iris-messenger-store', {
    state: () => ({
        messages_dialog: new Map(),
        messages_flash: new Map(),
        messages_toast: new Map(),
        messages_confirm: new Map(),
        messages_bar: new Map(),
        messages_loader: new Map(),
    }),
    actions: {
        /**
         * Zeigt ein Dialogfenster in der Mitte des Bildschirms an
         * @param type
         * @param title
         * @param message
         * @param callback
         * @param selfDestructTimer
         */
        dialog ({type, title, message, callback = null, selfDestructTimer = false}) {
            this.messages_dialog.set(message.id, {type, title, message, callback, selfDestructTimer});
            /**
             * selfDestructTimer
             */
            if (selfDestructTimer) {
                setTimeout(() => {
                    this.close(message.id)
                }, selfDestructTimer)
            }
        },

        /**
         * Zeigt eine Flash-Nachricht rechts unten im Bildschirm an
         * @param type
         * @param title
         * @param message
         * @param callback
         * @param selfDestructTimer
         */
        flash ({type, title, message, selfDestructTimer = false}) {
            this.messages_flash.set(message.id, {type, title, message, selfDestructTimer})
            /**
             * selfDestructTimer
             */
            if (selfDestructTimer) {
                setTimeout(() => {
                    this.close(message.id)
                }, selfDestructTimer)
            }
        },
        /**
         * Zeigt einen Loader in der Mitte des Bildschirms an, der alle Interaktionen blockiert
         * @param type
         * @param title
         * @param message
         * @param callback
         * @param {boolean|number} selfDestructTimer
         * */
        openLoader ({type, title, message, selfDestructTimer = false}) {
            this.messages_loader.set(0, {type, title, message, selfDestructTimer})
            /**
             * selfDestructTimer
             */
            if (selfDestructTimer) {
                setTimeout(() => {
                    this.closeLoader(0)
                }, selfDestructTimer)
            }
        },
        /**
         * Schließt den Loader
         */
        closeLoader () {
            this.messages_loader.delete(0)
        },

        /**
         * Zeigt eine Dialogfeld in der Mitte des Bildschirms an, das bestätigt werden muss
         * @param type
         * @param title
         * @param message
         * @param callback
         * @param {boolean|integer} selfDestructTimer
         */
        confirm ({type, title, message, callback = null, selfDestructTimer = false}) {
            this.messages_confirm.set(message.id, {type, title, message, callback, selfDestructTimer})
        },

        /**
         * Schließt ein Dialogfenster
         * @param messageId
         */
        close (messageId) {
            this.messages_dialog.delete(messageId)
            this.messages_flash.delete(messageId)
            this.messages_toast.delete(messageId)
            this.messages_confirm.delete(messageId)
            this.messages_bar.delete(messageId)
        },
        /**
         * Führt die Callback-Funktion aus und schließt das Dialogfenster
         * @param type
         * @param title
         * @param message
         * @param callback
         * @param selfDestructTimer
         */
        confirmAction ({type, title, message, callback = null, selfDestructTimer = false}) {
            if (callback !== null) {
                (callback())
            }
            this.close(message.id)
        }
    },
    getters: {
        dialogMessages () {
            return Array.from(this.messages_dialog.values());
        },
        flashMessages () {
            return Array.from(this.messages_flash.values());
        },
        toastMessages () {
            return Array.from(this.messages_toast.values());
        },
        confirmMessages () {
            return Array.from(this.messages_confirm.values());
        },
        barMessages () {
            return Array.from(this.messages_bar.values());
        },
        loaderMessages () {
            return Array.from(this.messages_loader.values());
        }
    }
})